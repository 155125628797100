<template>
  <CContainer>
  <CRow>
     <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Creditos del Usuario {{usuario.name}}</h3> 
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol col="12">
              <table class="table">
               <thead>
                 <tr>
                   <td><strong>Creditos SMS</strong></td>
                   <td>{{creditosConversorMoneda(creditos_sms)}}</td>
                   <td>
                        <CButton variant="outline" color="info" shape="pill" @click="creditos_modal = true">Asignar Creditos</CButton>
                   </td>
                 </tr>
               </thead>
            </table>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
     </CCol>
  </CRow>
    <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <h3>Historial de Recargas</h3>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            hover
            striped
            :items="items"
            :fields="fields"
            :items-per-page="10"
            :pagination="{ doubleArrows: false, align: 'center'}"
            column-filter
            @page-change="pageChange"
          >
            <template #creditos="{item, index}">
                <td>
                   {{creditosConversorMoneda(item.creditos)}}
                </td>
            </template>
          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
         <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  Recarga Efectuada Correctamente.
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
          <CModal
            :show.sync="creditos_modal"
            :no-close-on-backdrop="true"
            :centered="true"
            title="Modal title 2"
            size="lg"
            color="info"
          >
            <CRow>
              <CCol col="12">
                Usted esta Apunto de Asignar Mensajes de SMS a el Usuario {{usuario.name}}
              </CCol>
              <CCol col="12">
                <CInput
                    label="Creditos Asignar"
                    placeholder="Creditos Asignar"
                    autocomplete="celular"
                    minlength="1"
                    tabindex="0"
                    id="uid-ip8fkjcnasjl-recarga3"
                    required
                    was-validated
                    v-model.lazy="asignarCreditos.saldo" 
                    v-money="money"
                    >
                    </CInput>
              </CCol>
              <CCol col="12">
                 <div class="form-group">
                   <label for="">Detalle de la Asignacion</label>
                   <textarea
                      required
                      class="form-control"
                      v-model="asignarCreditos.description"
                    />
                 </div>
              </CCol>
              <CCol col="12">
                <CInputCheckbox
                      label="Confirmar Asignacion de Creditos"
                      :value="false"
                      :checked.sync="autorizacion_check"
                    />
              </CCol>
            </CRow>
            <template #header>
              <h6 class="modal-title">Asignar Creditos Sub Cuentas SMS</h6>
              <CButtonClose @click="creditos_modal = false" class="text-white"/>
            </template>
            <template #footer>
              <CButton @click="creditos_modal = false" color="danger">Cancel</CButton>
              <CButton @click="asignarCreditosFun()" color="success" v-if="$can('subcuenta.asignarcreditos')" :disabled="validaCheckAsignarCreditos">Aceptar</CButton>
            </template>
          </CModal>
        </div>
  </CContainer>
</template>

<script>
import {VMoney} from 'v-money'
export default {
  name: 'RecargasSubCuenta',
  components: {VMoney},
  data () {
    return {
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError:0,
      mensaje_warning:'Debe Completar Los Datos Vacio',
      historial_select:'sms',
      items: [],
      items_filter: [],
      fields: [
        { key: 'fecha_creacion',label:'Fecha',_classes: 'font-weight-bold'  },
        { key: 'creditos', label: 'Creditos'},
        { key: 'descripcion',label:'Descripcion'},
        
      ],
      activePage: 1,
      creditos_prueba_modal:false,
      creditos_modal:false,
      usuario:{
        name: ''
      },
      asignarCreditos:{
        saldo:0.00,
        saldo_prueba:50.00,
        description:'',
      },
      creditos_sms:0.00,
      money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 0,
          masked: true
      },
      autorizacion_check:false,
    }
  },
  directives: {money: VMoney},
  methods: {
    goBack() {
      this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/roles'})
    },
    obtenerUsuarioHistorialPorId () {
        const id = this.$route.params.id
        const $self = this;
        this.$store.dispatch('auth/loading_http','visible');
        this.$http.get('/sms/subcuenta/recarga/'+id).then(response => {
            this.items_filter = response.data.historial;
            this.usuario = response.data.usuarios;
            this.creditos_sms = response.data.creditos.sms;
            $self.filtroItemsHistorial();
            this.asignarCreditos.saldo = 0.00;
            this.asignarCreditos.description = '';
        }).catch((e)=>{
          this.mostrarNotificacionError ++;evt.target.disabled = false;
        }).finally(() =>this.$store.dispatch('auth/loading_http','hidden'));
    },
    validator (val) {
      return val ? val.length >= 4 : false
    },
    isNumber: function(evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    asignarCreditosFun : function (evt) {
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      const id = this.$route.params.id
      let valida = this.validaFormSaldo();
      if (valida == false) {
        return false;
      }
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post('/sms/subcuenta/recarga/'+id,{
        creditos:this.asignarCreditos.saldo,
        description:this.asignarCreditos.description,
        tipo_recarga:'sms',
        saldo_prueba:0,
      }).then(response =>{
        let data = response.data;
        if (data == "OK") {
            evt.target.disabled = false;
            this.creditos_modal = false
            document.getElementById("uid-ip8fkjcnasjl-recarga3").value  = 0;
            this.mostrarNotificacion ++;
            this.obtenerUsuarioHistorialPorId();
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  mensajeError += `${element.mensaje} \n`
                  /*for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }*/
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;evt.target.disabled = false;}).finally(() =>this.$store.dispatch('auth/loading_http','hidden'))
    },
    filtroItemsHistorial(){
        this.items = this.items_filter.filter((p)=>p.tipo_historial == this.historial_select);
        this.items.map((item,id) => {  
          if (id%2 == 0) {
            item['_classes'] = 'table-now'; 
          }
          return item;
        })
    },
    pageChange (val) {
      this.$router.push({ query: { page: val }})
    },
    formatNumber(n) {
      // format number 1000000 to 1,234,567
      return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    creditosConversorMoneda(value){
        const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
        })
        return (formatterDolar.format(value)).replace('$','');
    },
    validaFormSaldo(){
      if ((this.asignarCreditos.description).trim() == '' || this.asignarCreditos.saldo == '0,00') {
          this.mostrarNotificacionAlerta++
          this.autorizacion_check = false
          return false;
      }else{
          return true;
      }
    }
  },
  mounted () {
      setTimeout(() => {
        this.obtenerUsuarioHistorialPorId();
      }, 100);
  },
  computed: {
    validaCheckAsignarCreditos(){
      return this.autorizacion_check  == false ? true : false;
    }
  }
}
</script>
